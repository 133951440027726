import { getType, addOrEdit, fileUploader, glanceView, fileRemove } from "@lib";
import TextEditer from "@component/TextEditer/index.vue";

export default {
  data() {
    return {
      // 表单信息
      addForm: {
        name: "",
        typeCode: "",
        content: "",
        file: "",
        subject: "",
        state: "1000",
      },
      addRules: {
        name: {
          required: true,
          message: "请输入方案名称",
          trigger: "blur",
        },
        subject: {
          required: true,
          message: "请输入方案简介",
          trigger: "blur",
        },
        typeCode: {
          required: true,
          message: "请选择解决方案类型",
          trigger: "change",
        },
        content: {
          required: true,
          message: "请输入产品解决方案内容",
          trigger: "blur",
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      typeArr: [],
      resId: "",
      liulanFiles: [],
      removeFileArr: [],
      fujianFiles: [],
    };
  },
  methods: {
    //   确认表单信息
    submitAccountForm(addForm) {
      this.$refs[addForm].validate((valid) => {
        if (valid) {
          if (this.$route.params.id === "undefined") {
            this.commitFN();
          } else {
            this.commitFN(this.$route.params.id);
          }
        } else {
          return false;
        }
      });
    },
    async commitFN(id) {
      if (this.removeFileArr.length !== 0) {
        this.removeFileFn();
      }
      let res = await addOrEdit({
        content: this.addForm.content,
        contentType: "html",
        id: id,
        subject: this.addForm.subject,
        name: this.addForm.name,
        publishState: "1000",
        typeCode: this.addForm.typeCode,
      });
      if (res.status === "success") {
        this.resId = res.result.id;
        if (this.fujianFiles.length) {
          let formD = new FormData();
          this.fujianFiles.forEach((item) => {
            formD.append("file", item);
          });
          fileUploader(formD, this.resId).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.$router.back();
            }
          });
        } else {
          this.$message.success("操作成功");
          this.$router.back();
        }
      }
    },
    handleRemove(file, fileList) {
      this.fujianFiles = fileList.map((item) => item.raw);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    textareaData(content) {
      this.addForm.content = JSON.parse(JSON.stringify(content));
    },

    goodNewPicFn(val) {
      this.fujianFiles.push(val.file);
    },

    clearFile(removeId, i) {
      this.liulanFiles.splice(i, 1);
      this.removeFileArr.push(removeId);
    },
    removeFileFn() {
      let formDa = new FormData();
      this.removeFileArr.forEach((item) => {
        formDa.append("id", item);
      });
      fileRemove(formDa);
    },
    handleExceed(files, fileList) {
      this.$message.warning("图片只能上传一张");
      this.fujianFiles = fileList;
    },
  },
  async created() {
    let res = await getType();
    this.typeArr = res.result;
    if (this.routerParams.id == undefined) {
      this.addForm.typeCode = res.result[0].code;
    } else {
      let re = await glanceView({ id: this.routerParams.id });
      console.log("re.result", re.result);
      this.addForm.content = re.result.content;
      this.addForm.name = re.result.name;
      this.addForm.state = re.result.publishState;
      this.addForm.typeCode = re.result.typeCode;
      this.addForm.subject = re.result.subject;
      this.liulanFiles = re.result.files;
    }
  },
  components: {
    [TextEditer.name]: TextEditer,
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
};
